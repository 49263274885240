import type { valueof } from 'utils/typeUtils';
import { objectEntries } from 'utils/typeUtils';

import type { DefaultTheme } from 'styled-components';

const color = {
  common: {
    white: '#ffffff',
    black: '#000000',
    transparent: 'transparent',
  },
  gray: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#1A1A1A',
  },
  blue: {
    25: '#F0FCFF',
    50: '#DEF3F9',
    100: '#AEE1EF',
    200: '#7ACEE4',
    300: '#48BAD6',
    400: '#25ADCC',
    500: '#10A0C1',
    600: '#0C91AF',
    700: '#057E96',
    800: '#026B7E',
    900: '#004953',
  },
  green: {
    25: '#ECF8F8',
    50: '#E1F0F0',
    100: '#B6DBD9',
    200: '#88C4C1',
    300: '#5FADA8',
    400: '#479C95',
    500: '#348B83',
    600: '#317F76',
    700: '#2C6F67',
    800: '#275F58',
    900: '#1D443D',
  },
  purple: {
    25: '#F6F7FF',
    50: '#F1E4EF',
    100: '#DCBBD9',
    200: '#C58FC0',
    300: '#AE65A7',
    400: '#9C4795',
    500: '#8B2C84',
    600: '#80287E',
    700: '#702276',
    800: '#611D6D',
    900: '#47165C',
  },
  alphaWhite: {
    25: '#FFFFFF06',
    50: '#FFFFFF0d',
    100: '#FFFFFF1a',
    200: '#FFFFFF1c',
    300: '#FFFFFF4d',
    400: '#FFFFFF66',
    500: '#FFFFFF80',
    600: '#FFFFFF99',
    700: '#FFFFFFb3',
    800: '#FFFFFFcc',
    900: '#FFFFFFe6',
  },
  alphaGray: {
    25: '#EAECF006',
    50: '#EAECF00d',
    100: '#EAECF01a',
    200: '#EAECF01c',
    300: '#EAECF04d',
    400: '#EAECF066',
    500: '#EAECF080',
    600: '#EAECF099',
    700: '#EAECF0b3',
    800: '#EAECF0cc',
    900: '#EAECF0e6',
  },
  alphaBlack: {
    25: '#00000006',
    50: '#0000000d',
    100: '#0000001a',
    200: '#00000033',
    300: '#0000004d',
    400: '#00000066',
    500: '#00000080',
    600: '#00000099',
    700: '#000000b3',
    800: '#000000cc',
    900: '#000000e6',
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
} as const;

export type GetColorOptions<T> = {
  readonly [P in keyof T]: keyof T[P];
};

export type GetColorList<T> = {
  readonly [P in keyof T]: `${P extends number | string ? P : never}-${keyof T[P] extends string | number
    ? keyof T[P]
    : never}`;
}[keyof T];

export const globalValues = ['inherit', 'initial', 'revert', 'revert-layer', 'unset'] as const;
export type GlobalValues = typeof globalValues[number];

export type ColorObject = typeof color;
export type ColorOptions = GetColorOptions<ColorObject>;

export type ColorList = GetColorList<ColorObject> | GlobalValues;
export type ColorDefinition = keyof ColorObject;
export const colorListArray = objectEntries(color)
  .map(([colorName, colorValues]) => Object.keys(colorValues).map(value => `${colorName}-${value}`))
  .flat() as ColorList[];

export interface LightAndDark {
  light: ColorList;
  dark: ColorList;
  isAlwaysDark: boolean;
}

type Common = ColorOptions['common'];
type Others = valueof<Omit<ColorOptions, 'common'>>;
type ColorArray = [ColorDefinition, Common | Others];

export type GetColorDefinition = ColorList | LightAndDark;

export const getColor = (palette: DefaultTheme['palette'], colors: GetColorDefinition) => {
  if (!colors) {
    return;
  }

  if (typeof colors === 'string' && globalValues.some(value => value === colors)) {
    return colors;
  }
  const [colorKey, colorValue] =
    typeof colors === 'string'
      ? (colors.split('-') as ColorArray)
      : (colors[colors.isAlwaysDark ? 'dark' : palette.mode].split('-') as ColorArray);
  const subPalette = palette[colorKey];

  return 'white' in subPalette ? subPalette[colorValue as Common] : subPalette[colorValue as Others];
};

export default color;
