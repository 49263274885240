/* eslint-disable @typescript-eslint/ban-types */
import type { DefaultTheme } from 'styled-components';

const shadow = {
  xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
  lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  xxl: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
  xxxl: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
  xsInset: '0px 1px 2px rgba(16, 24, 40, 0.05) inset',
  smInset: '0px 1px 3px rgba(16, 24, 40, 0.1) inset, 0px 1px 2px rgba(16, 24, 40, 0.06) inset',
  mdInset: '0px 4px 8px -2px rgba(16, 24, 40, 0.1) inset, 0px 2px 4px -2px rgba(16, 24, 40, 0.06) inset',
  lgInset: '0px 12px 16px -4px rgba(16, 24, 40, 0.08) inset, 0px 4px 6px -2px rgba(16, 24, 40, 0.03) inset',
  xlInset: '0px 20px 24px -4px rgba(16, 24, 40, 0.08) inset, 0px 8px 8px -4px rgba(16, 24, 40, 0.03) inset',
  xxlInset: '0px 24px 48px -12px rgba(16, 24, 40, 0.18) inset',
  xxxlInset: '0px 32px 64px -12px rgba(16, 24, 40, 0.14) inset',
  colorful:
    '15px 30px 25px rgba(95, 173, 168, 0.3), -25px -25px 25px rgba(72, 186, 214, 0.3), 30px -35px 25px rgba(174, 101, 167, 0.3)',
  green: '-15px 30px 25px #E1F0F0, -25px -25px 25px #ECF8F8, 30px -35px 25px #E1F0F0',
  darkgreen:
    '-15px 30px 25px rgba(29, 68, 61, 0.6), -25px -25px 25px rgba(49, 127, 118, 0.6), 30px -35px 25px rgba(49, 127, 118, 0.6)',
  purple: '15px 30px 25px #F1E4EF, -25px -25px 25px #F6F7FF, 30px -35px 25px #F1E4EF',
  blue: '-15px 30px 25px #DEF3F9, -25px -25px 25px #F0FCFF, 30px -35px 25px #DEF3F9',
  navDark:
    '0px 0px 0px 0px rgba(0, 126, 112, 0.1), 0px 21px 46px 0px rgba(0, 126, 112, 0.1), 0px 83px 83px 0px rgba(0, 126, 112, 0.09), 0px 187px 112px 0px rgba(0, 126, 112, 0.05), 0px 333px 133px 0px rgba(0, 126, 112, 0.01), 0px 520px 146px 0px rgba(0, 126, 112, 0)',
  navLight:
    '0px 0px 0px 0px rgba(204, 204, 204, 0.1), 0px 21px 46px 0px rgba(204, 204, 204, 0.1), 0px 83px 83px 0px rgba(204, 204, 204, 0.09), 0px 187px 112px 0px rgba(204, 204, 204, 0.05), 0px 333px 133px 0px rgba(204, 204, 204, 0.01), 0px 520px 146px 0px rgba(204, 204, 204, 0)',
  greenSm: '0px 4px 40px 0px rgba(95, 173, 168, 0.3)',
  greenMd: '0px 4px 40px rgba(105, 228, 255, 0.3)',
  lights: '15px 7px 10px 30px #3BC5AB55, 15px 7px 10px 20px #48BAD655',
} as const;

type ShadowKeys = keyof typeof shadow;
interface ShadowLightDark {
  light: ShadowKeys | (string & {});
  dark: ShadowKeys | (string & {});
  isAlwaysDark: boolean;
}

export type ShadowDefinition = ShadowKeys | ShadowLightDark | (string & {});

const parseInputString = (string: string) => {
  if (Object.keys(shadow).includes(string)) {
    return shadow[string as ShadowKeys];
  }

  return string;
};

export const parseShadow = (palette: DefaultTheme['palette'], input: ShadowDefinition) => {
  if (!input) {
    return;
  }
  if (typeof input === 'object') {
    return parseInputString(input[input.isAlwaysDark ? 'dark' : palette.mode]);
  }

  return parseInputString(input);
};

export default shadow;
