const textFont = '"PPNeueMachina-Inktrap", sans-serif';
const richTextFont = "'Inter', sans-serif";

export const fontFamily = {
  textFont,
  richTextFont,
} as const;

export const fontSize = {
  textXs: '0.75rem',
  textSm: '0.875rem',
  textMd: '1rem',
  textLg: '1.125rem',
  textXl: '1.25rem',
  textXXl: '1.5rem',
  displayXs: '1.5rem',
  displaySm: '1.875rem',
  displayMd: '2.25rem',
  displayLg: '3rem',
  displayXl: '3.75rem',
  display2xl: '5rem',
  richXs: '1rem',
  richSm: '1.1875rem',
  richMd: '1.4375rem',
  richLg: '1.6875rem',
  richXl: '2.0625rem',
  rich2xl: '3.125rem',
} as const;

export const lineHeight = {
  zero: 0,
  xs: '1.125rem',
  sm: '1.25rem',
  md: '1.5rem',
  lg: '1.75rem',
  xl: '1.875rem',
  xxl: '2rem',
  displayXs: '2rem',
  displaySm: '2.375rem',
  displayMd: '2.75rem',
  displayLg: '3.75rem',
  displayXl: '4.5rem',
  display2xl: '5rem',
  richBody: '1.8em',
  richHeading: '1.2em',
} as const;

export const letterSpacing = {
  neg3: '-0.03em',
  neg2: '-0.02em',
  0: '0em',
  sm: '0.1em',
} as const;

export const fontWeight = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
} as const;

export const font = {
  text: {
    xs: {
      fontFamily: textFont,
      fontSize: fontSize.textXs,
      lineHeight: lineHeight.xs,
      font() {
        return `${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    sm: {
      fontFamily: textFont,
      fontSize: fontSize.textSm,
      lineHeight: lineHeight.sm,
      font() {
        return `${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    md: {
      fontFamily: textFont,
      fontSize: fontSize.textMd,
      lineHeight: lineHeight.md,
      font() {
        return `${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    lg: {
      fontFamily: textFont,
      fontSize: fontSize.textLg,
      lineHeight: lineHeight.lg,
      font() {
        return `${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    xl: {
      fontFamily: textFont,
      fontSize: fontSize.textXl,
      lineHeight: lineHeight.xl,
      font() {
        return `${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    xxl: {
      fontFamily: textFont,
      fontSize: fontSize.textXXl,
      lineHeight: lineHeight.xxl,
      font() {
        return `${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    eyebrow: {
      fontFamily: textFont,
      fontSize: fontSize.textMd,
      lineHeight: lineHeight.md,
      fontWeight: fontWeight.bold,
      font() {
        return `${this.fontSize}/${this.lineHeight} ${this.fontFamily}; 
        text-transform: uppercase;
        font-weight: regular;
        letter-spacing: ${letterSpacing.sm}`;
      },
    },
    richBody: {
      fontFamily: richTextFont,
      fontSize: fontSize.textLg,
      lineHeight: lineHeight.richBody,
      fontWeight: fontWeight.regular,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    caption: {
      fontFamily: richTextFont,
      fontSize: fontSize.textSm,
      lineHeight: lineHeight.richBody,
      fontWeight: fontWeight.light,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    blockQuote: {
      fontFamily: textFont,
      fontSize: fontSize.displaySm,
      lineHeight: lineHeight.richBody,
      fontWeight: fontWeight.semiBold,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
  },
  display: {
    xs: {
      fontFamily: textFont,
      fontSize: fontSize.displayXs,
      lineHeight: lineHeight.displayXs,
      fontWeight: fontWeight.regular,
      letterSpacing: letterSpacing[0],
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    sm: {
      fontFamily: textFont,
      fontSize: fontSize.displaySm,
      lineHeight: lineHeight.displaySm,
      fontWeight: fontWeight.regular,
      letterSpacing: letterSpacing[0],
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    md: {
      fontFamily: textFont,
      fontSize: fontSize.displayMd,
      lineHeight: lineHeight.displayMd,
      fontWeight: fontWeight.regular,
      letterSpacing: letterSpacing.neg2,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    lg: {
      fontFamily: textFont,
      fontSize: fontSize.displayLg,
      lineHeight: lineHeight.displayLg,
      fontWeight: fontWeight.regular,
      letterSpacing: letterSpacing.neg2,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    xl: {
      fontFamily: textFont,
      fontSize: fontSize.displayXl,
      lineHeight: lineHeight.displayXl,
      fontWeight: fontWeight.regular,
      letterSpacing: letterSpacing.neg2,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    xxl: {
      fontFamily: textFont,
      fontSize: fontSize.display2xl,
      lineHeight: lineHeight.display2xl,
      fontWeight: fontWeight.regular,
      letterSpacing: letterSpacing.neg2,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    richXs: {
      fontFamily: textFont,
      fontSize: fontSize.richXs,
      lineHeight: lineHeight.richHeading,
      fontWeight: fontWeight.semiBold,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    richSm: {
      fontFamily: textFont,
      fontSize: fontSize.richSm,
      lineHeight: lineHeight.richHeading,
      fontWeight: fontWeight.semiBold,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    richMd: {
      fontFamily: textFont,
      fontSize: fontSize.richMd,
      lineHeight: lineHeight.richHeading,
      fontWeight: fontWeight.semiBold,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    richLg: {
      fontFamily: textFont,
      fontSize: fontSize.richLg,
      lineHeight: lineHeight.richHeading,
      fontWeight: fontWeight.semiBold,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    richXl: {
      fontFamily: textFont,
      fontSize: fontSize.richXl,
      lineHeight: lineHeight.richHeading,
      fontWeight: fontWeight.semiBold,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
    rich2xl: {
      fontFamily: textFont,
      fontSize: fontSize.rich2xl,
      lineHeight: lineHeight.richHeading,
      fontWeight: fontWeight.semiBold,
      letterSpacing: letterSpacing.neg3,
      font() {
        return `${this.fontWeight} ${this.fontSize}/${this.lineHeight} ${this.fontFamily}`;
      },
    },
  },
} as const;

export type FontWeightDefinition = keyof typeof fontWeight;
export type FontSizeDefinition = keyof typeof fontSize;
export type FontFamilyDefinition = keyof typeof fontFamily;
export type LineHeightDefinition = keyof typeof lineHeight;
export type LetterSpacingDefinition = keyof typeof letterSpacing;
